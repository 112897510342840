<template>
  <div class="mt-2 row no-gutters mx-0">
    <div class="col-1">
      <nav class="navbar-light text-left
                    text-smallest font-weight-bold
                    side-nav mr-2 bg-white min-vh-100">
        <ul class="nav flex-column h-100p text-left">
          <li class="nav-item">
            <router-link :to="{name:'CrewInternational'}" tag="a"
                         active-class="active"
                         class="nav-link text-dark">
              International Documents
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'CrewCertificates'}" tag="a"
                         active-class="active"
                         class="nav-link text-dark">
              Certificates
            </router-link>

          </li>
          <li class="nav-item">
            <router-link :to="{name:'CrewTraining'}" tag="a"
                         active-class="active"
                         class="nav-link text-dark">
              Other Trainings
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'CrewLicences'}" tag="a"
                         active-class="active"
                         class="nav-link text-dark">
              Licenses
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'CrewFlagDocument'}" tag="a"
                         active-class="active"
                         class="nav-link text-dark">
              Flag Documents
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'CrewMedical'}" tag="a"
                         active-class="active"
                         class="nav-link text-dark">
              Medical Documents
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :to="{name:'CrewAssessmentReport'}" tag="a"
                         active-class="active"
                         class="nav-link text-dark">
              Assessment Report
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-11">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  name: "Document"
}
</script>


<style scoped>
.nav-link.active,.nav-link:hover {
  background-color: #e87c87;
  color: white !important;
}
</style>

